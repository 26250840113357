import { useContext } from 'react';
import { GridListSelectableContext } from './context';
export const useGridListSelectable = () => {
  const {
    identifierKey,
    isSelectAll: isSelectAllProvided,
    items,
    selectedItems,
    onSelect,
    onSelectAllToggle
  } = useContext(GridListSelectableContext);
  const countSelectedItems = selectedItems.length;
  const countItems = items.length;
  const hasSelectedItems = countSelectedItems > 0;
  const isSelectAll = isSelectAllProvided !== null && isSelectAllProvided !== void 0 ? isSelectAllProvided : hasSelectedItems && countSelectedItems === countItems;
  const isIndeterminate = countSelectedItems > 0 && countSelectedItems < countItems;
  return {
    countItems,
    countSelectedItems,
    hasSelectedItems,
    identifierKey,
    isIndeterminate,
    isSelectAll,
    items,
    onSelect,
    onSelectAllToggle,
    selectedItems
  };
};