import { useTheme } from './useTheme';
const BREEZE_BRAND = '#E20185';
const borderRadiusMap = {
  sm: '0.125rem',
  default: '3px',
  md: '0.25rem',
  lg: '0.5rem',
  xl: '1rem',
  xxl: '2rem',
  full: '100%'
};
export const useTokens = () => {
  const {
    borderRadius,
    borderWidth,
    boxShadow,
    color,
    space
  } = useTheme();
  const tokens = {
    backgroundColor: color['fill-surface-raised'],
    backgroundColorLight: color['fill-surface-default'],
    borderColor: color['border-core-subtle'],
    borderColorLight: color['border-core-subtle'],
    borderWidth: borderWidth[100],
    borderRadius: {
      sm: borderRadiusMap.sm,
      default: borderRadius[100],
      md: borderRadiusMap.md,
      lg: borderRadiusMap.lg,
      xl: borderRadiusMap.xl,
      xxl: borderRadiusMap.xxl,
      full: borderRadiusMap.full
    },
    breezeBrand: BREEZE_BRAND,
    shadow: {
      card: boxShadow[100]
    },
    space: {
      xxxxs: space[25],
      xxxs: space[50],
      xxs: space[100],
      xs: space[200],
      sm: space[300],
      default: space[400],
      md: space[600],
      lg: space[800],
      xl: space[1000],
      xxl: space[1100],
      xxxl: space[1400],
      xxxxl: space[1500]
    },
    textColor: {
      base: color['text-core-default'],
      danger: color['text-alert-default'],
      disabled: color['text-core-disabled'],
      extraLight: color['text-primary-subtle'],
      light: color['text-primary-subtle'],
      inverse: color['text-primary-on-fill-default']
    }
  };
  return {
    tokens
  };
};