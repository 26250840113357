import { CHICLET_LIST, DEFAULT_LOADING_COMPONENT_PROPS, SPINNER } from 'crm-cards-sdk/constants/LoadingComponentProps';
export function getLoadingComponentProps(cardData, __cardLocationContext) {
  const legacySidebarCardType = cardData.configuration.cardType;
  switch (legacySidebarCardType) {
    case 'ContactCreateAttributionCard':
      return [{
        type: SPINNER,
        minHeight: 364
      }];
    // Chart height
    case 'EngagementAttachmentsCard':
      return [{
        type: CHICLET_LIST,
        numberOfSkeletons: 1,
        numberOfProperties: 0
      }];
    case 'GdprSubscriptionsCard':
      return [{
        type: SPINNER,
        minHeight: 64
      }];
    case 'LeadStageTrackerCard':
      return [{
        type: SPINNER,
        minHeight: 210
      }];
    case 'LinkedInSalesNavigatorCard':
      return [{
        type: SPINNER,
        minHeight: 412
      }];
    // Hardcoded LSN iframe height in sidebar
    case 'ListsCard':
      return [{
        type: SPINNER,
        minHeight: 24
      }];
    // One line of text
    case 'PlaybooksCard':
      return [{
        type: SPINNER,
        minHeight: 200
      }];
    case 'WebsiteActivityCard':
      return [{
        type: SPINNER,
        minHeight: 270
      }];
    case 'WorkflowsCard':
      return [{
        type: SPINNER,
        minHeight: 24
      }];
    // One line of text
    default:
      return DEFAULT_LOADING_COMPONENT_PROPS;
  }
}