import { Loadable } from 'foundations-components/transitional/utils/Loadable';
import { AsyncComponentErrorHandler, AsyncComponentLoadingHandler } from 'crm-events-components';
const PortalUsersPanelAsync = Loadable({
  loader: () => import(
  // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'BizO... Remove this comment to see the full error message
  /* webpackChunkName: "portal-users-panel" */
  'BizOpsCrmUIComponents/components/customerSummary/hubSummary/summaryRowSections/PortalUsersPanel').then(mod => mod.default),
  LoadingComponent: AsyncComponentLoadingHandler(),
  ErrorComponent: AsyncComponentErrorHandler('portal-users-panel')
});
export default PortalUsersPanelAsync;