import enviro from 'enviro';
import { createTracker } from 'usage-tracker';
import { withSessionReplay } from 'usage-tracker-session-replay';
import Raven from 'raven-js';
import events from '../events.yaml';
const DEFAULT_TRACKER_PROPS = {
  events,
  properties: {
    namespace: 'commerce-billing'
  },
  onError: err => {
    Raven.captureMessage('commerce-billing-lib tracker event error', {
      stacktrace: true,
      extra: {
        message: err.message
      }
    });
  }
};
export const tracker = createTracker(DEFAULT_TRACKER_PROPS);
export const trackerWithSessionReplay = withSessionReplay(tracker, {
  sampleRate: 0.01
});
export function interaction(action) {
  return value => {
    var _value, _value$toString;
    if (typeof value === 'object') {
      value = undefined;
    }
    value = (_value = value) === null || _value === void 0 || (_value$toString = _value.toString) === null || _value$toString === void 0 ? void 0 : _value$toString.call(_value);
    if (enviro.isQa()) {
      /* eslint-disable-next-line no-console */
      console.log(value ? `interaction = "${action}", value = "${value}"` : `interaction = "${action}"`);
    }
    trackerWithSessionReplay.track('interactionEvent', {
      action,
      value
    });
  };
}

// track all the stuff users interact with
export const billWizEvents = Object.freeze({
  contact: {
    select: interaction('click:contact/select'),
    selected: interaction('click:contact/select/selectedExistingContact'),
    edit: interaction('click:contact/edit'),
    editFirstname: interaction('click:contact/edit/firstname'),
    editLastname: interaction('click:contact/edit/lastname'),
    editEmail: interaction('click:contact/edit/email'),
    editJobtitle: interaction('click:contact/edit/jobtitle'),
    editPhone: interaction('click:contact/edit/phone'),
    editSubmit: interaction('click:contact/edit/submit'),
    editCancel: interaction('click:contact/edit/cancel'),
    addExisting: interaction('click:contact/addExisting'),
    createNew: interaction('click:contact/createNew'),
    createNewFirstname: interaction('click:contact/createNew/firstname'),
    createNewLastname: interaction('click:contact/createNew/lastname'),
    createNewEmail: interaction('click:contact/createNew/email'),
    createNewJobtitle: interaction('click:contact/createNew/jobtitle'),
    createNewPhone: interaction('click:contact/createNew/phone'),
    createNewSubmit: interaction('click:contact/createNew/submit'),
    createNewCancel: interaction('click:contact/createNew/cancel'),
    useBillingContact: interaction('click:confirmUseBillingContact')
  },
  company: {
    select: interaction('click:company/select'),
    selected: interaction('click:company/select/selectedExistingCompany'),
    removed: interaction('click:company/select/removedSelectedCompany'),
    edit: interaction('click:company/edit'),
    editName: interaction('click:company/edit/name'),
    editAddress: interaction('click:company/edit/address'),
    editAddress2: interaction('click:company/edit/address2'),
    editState: interaction('click:company/edit/state'),
    editCity: interaction('click:company/edit/city'),
    editZip: interaction('click:company/edit/zip'),
    editCountry: interaction('click:company/edit/country'),
    editSubmit: interaction('click:company/edit/submit'),
    editCancel: interaction('click:company/edit/cancel'),
    addExisting: interaction('click:company/addExisting'),
    createNew: interaction('click:company/createNew'),
    createNewName: interaction('click:company/createNew/name'),
    createNewAddress: interaction('click:company/createNew/address'),
    createNewAddress2: interaction('click:company/createNew/address2'),
    createNewState: interaction('click:company/createNew/state'),
    createNewCity: interaction('click:company/createNew/city'),
    createNewZip: interaction('click:company/createNew/zip'),
    createNewCountry: interaction('click:company/createNew/country'),
    createNewSubmit: interaction('click:company/createNew/submit'),
    createNewCancel: interaction('click:company/createNew/cancel')
  },
  billingAddress: {
    edit: interaction('click:billingAddress/edit'),
    address: interaction('click:billingAddress/address'),
    address2: interaction('click:billingAddress/address2'),
    state: interaction('click:billingAddress/state'),
    city: interaction('click:billingAddress/city'),
    zip: interaction('click:billingAddress/zip'),
    country: interaction('click:billingAddress/country'),
    submit: interaction('click:billingAddress/submit'),
    cancel: interaction('click:billingAddress/cancel'),
    toggleUseCompanyAddress: interaction('toggle:billingAddress/useCompanyAddress')
  }
});