import { getCardKey } from './getCardKey';
import { useSelector } from './LocationStateContext';
export function useSelectPerfStateForCard({
  cardType,
  cardId,
  tabIndex
}) {
  return useSelector(state => getPerfStateForCard(state, cardType, cardId, tabIndex));
}
export function getPerfStateForCard(state, cardType, cardId, tabIndex) {
  return state.dataByCard[getCardKey(cardType, cardId, state.location, tabIndex)];
}
export function useAreInitialCardsReady() {
  return useSelector(state => Boolean(state.isInitialLoadSettled));
}