import { ModalDisplay } from '../../types/paymentsInterstitialModal';
import { Subscreen } from '../../types/screen';
import CRMModal from './CRMModal';
import CollectPaymentsWithHubSpotModal from './CollectPaymentsWithHubSpotModal';
import CollectPaymentsWithInvoicesModal from './CollectPaymentsWithInvoicesModal';
import CollectPaymentsWithSubscriptionsModal from './CollectPaymentsWithSubscriptions';
import CreateAPaymentLinkPreEnrollModal from './CreateAPaymentLinkModal/PreEnrollModal';
import CreateAPaymentLinkPostEnrollModal from './CreateAPaymentLinkModal/PostEnrollModal';
import EnrollInHubSpotPaymentsInvoicesModal from './EnrollInHubSpotPaymentsInvoicesModal';
import EnrollInHubSpotPaymentsPaymentLinksModal from './EnrollInHubSpotPaymentsPaymentLinksModal';
import GetPaidWithPaymentLinksModal from './GetPaidWithPaymentLinksModal';
import PaymentLinksModal from './PaymentLinksModal';
import { PaymentLinkStatus } from '../../hooks/toolStatus/usePaymentLinkStatus';
export const crmModals = {
  [Subscreen.CRM_PAYMENTS]: ModalDisplay.COLLECT_PAYMENTS,
  [Subscreen.CRM_PAYMENT_LINKS]: ModalDisplay.COLLECT_WITH_PAYMENT_LINKS,
  [Subscreen.CRM_SUBSCRIPTIONS]: ModalDisplay.COLLECT_WITH_SUBSCRIPTIONS,
  [Subscreen.CRM_INVOICES]: ModalDisplay.COLLECT_WITH_INVOICES
};
export const meetingsModals = {
  [PaymentLinkStatus.PRE_ENROLL_PRE_TOOL]: ModalDisplay.CREATE_A_PAYMENT_LINK_PRE_ENROLL,
  [PaymentLinkStatus.POST_ENROLL_PRE_TOOL]: ModalDisplay.CREATE_A_PAYMENT_LINK_POST_ENROLL,
  [PaymentLinkStatus.PRE_ENROLL_POST_TOOL]: ModalDisplay.ENROLL_WITH_PAYMENT_LINKS,
  [PaymentLinkStatus.POST_ENROLL_POST_TOOL]: ModalDisplay.NONE,
  [PaymentLinkStatus.LOADING]: ModalDisplay.NONE
};
const modals = {
  [ModalDisplay.PAYMENT_LINK]: PaymentLinksModal,
  [ModalDisplay.CRM]: CRMModal,
  [ModalDisplay.NONE]: () => null,
  [ModalDisplay.COLLECT_PAYMENTS]: CollectPaymentsWithHubSpotModal,
  [ModalDisplay.COLLECT_WITH_PAYMENT_LINKS]: GetPaidWithPaymentLinksModal,
  [ModalDisplay.COLLECT_WITH_SUBSCRIPTIONS]: CollectPaymentsWithSubscriptionsModal,
  [ModalDisplay.COLLECT_WITH_INVOICES]: CollectPaymentsWithInvoicesModal,
  [ModalDisplay.ENROLL_WITH_INVOICES]: EnrollInHubSpotPaymentsInvoicesModal,
  [ModalDisplay.ENROLL_WITH_PAYMENT_LINKS]: EnrollInHubSpotPaymentsPaymentLinksModal,
  [ModalDisplay.CREATE_A_PAYMENT_LINK_PRE_ENROLL]: CreateAPaymentLinkPreEnrollModal,
  [ModalDisplay.CREATE_A_PAYMENT_LINK_POST_ENROLL]: CreateAPaymentLinkPostEnrollModal
};
export default modals;