// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { defineKeyStore } from 'crm_data/store/KeyStore';
import { ADD_ENGAGEMENT, CHECK_SUBJECT_AND_ADD_ENGAGEMENT, ADD_SAMPLE_TIMELINE_ITEMS, DELETE_TIMELINE_ENGAGEMENT, ENGAGEMENTS_UPDATED, TASKS_AND_ENGAGEMENTS_UPDATED, FETCH_TIMELINE_ITEMS_SUCCESS, POLLING_FETCH_TIMELINE_ITEMS_SUCCESS, FETCHING_INITIAL_TIMELINE_EVENTS_STARTED, EMAIL_TRACKING_EVENT_DELETED
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
} from 'crm_data/actions/ActionTypes';
import I18n from 'I18n';
import { Set as ImmutableSet, Map as ImmutableMap } from 'immutable';
import { getTimelineItemTimestamp } from '../helpers/timelineItemComparator';
import { TASK } from 'customer-data-objects/engagement/EngagementTypes';
import get from 'transmute/get';
import { TRACKED_EVENT_TYPES, EVENT_SIDEKICK } from '../constants/TimelineConstants';
import { normalizeTypeId } from 'customer-data-objects/constants/ObjectTypeIds';
const {
  EMAIL_CLICK,
  EMAIL_OPEN
} = TRACKED_EVENT_TYPES;
export const TIMELINE_FUTURE_KEY = 'FUTURE';
const COMPLETED = 'COMPLETED';
function getId(timelineItem) {
  const appId = timelineItem.getIn(['eventData', 'applicationId']);
  const id = timelineItem.getIn(['eventData', 'sentBy', 'id']) || timelineItem.getIn(['engagement', 'id']) || timelineItem.getIn(['eventData', 'engagement', 'id']) || timelineItem.getIn(['eventData', 'id']) || timelineItem.get('id');
  const eventType = timelineItem.getIn(['engagement', 'type']) || timelineItem.getIn(['eventData', 'engagement', 'type']) || timelineItem.get('etype');
  const createdBy = timelineItem.getIn(['engagement', 'createdBy']) || timelineItem.getIn(['eventData', 'engagement', 'createdBy']);
  const ownerId = timelineItem.getIn(['engagement', 'ownerId']) || timelineItem.getIn(['eventData', 'engagement', 'ownerId']);
  const teamId = timelineItem.getIn(['engagement', 'teamId']) || timelineItem.getIn(['eventData', 'engagement', 'teamId']);
  const clientFilterTypes = timelineItem.getIn(['eventData', 'clientFilterTypes']);
  if (appId) {
    const integrationAppId = `integrations-app-${appId}`;
    return ImmutableMap({
      eventType,
      id: `${id}`,
      integrationAppId
    });
  }
  return ImmutableMap({
    clientFilterTypes,
    createdBy: createdBy && `${createdBy}`,
    eventType,
    id: `${id}`,
    ownerId: ownerId && `${ownerId}`,
    teamId: teamId && `${teamId}`
  });
}
function getMonthTimestamp(timelineItem) {
  const timestamp = getTimelineItemTimestamp(timelineItem);
  return I18n.moment.userTz(timestamp).endOf('month').valueOf();
}
function getTimelineItems(data) {
  if (data.sampleEvents) {
    return data.sampleEvents;
  }
  return data.get('events');
}
export function isFutureTimelineItem(timelineItem) {
  const now = I18n.moment.userTz();
  const timestamp = getTimelineItemTimestamp(timelineItem);
  const engagementType = timelineItem.getIn(['eventData', 'engagement', 'type']) || timelineItem.getIn(['engagement', 'type']);

  // overdue tasks live in the "Upcoming" section of timeline
  if (engagementType === TASK || timelineItem.getIn(['metadata', 'taskType'])) {
    const isTaskComplete = timelineItem.getIn(['eventData', 'metadata', 'status']) === COMPLETED || timelineItem.getIn(['metadata', 'status']) === COMPLETED;
    const isTaskOverdue = timestamp < now;
    if (isTaskOverdue) {
      return !isTaskComplete;
    }
  }
  return timestamp > now;
}
function getMonthKey(timelineItem) {
  if (isFutureTimelineItem(timelineItem)) {
    return TIMELINE_FUTURE_KEY;
  }
  return `${getMonthTimestamp(timelineItem)}`;
}
function getUpdatedEngagements(engagementsMap) {
  return engagementsMap.valueSeq().filter(engagement => engagement && engagement.size);
}
function getSidekickEvents(timelineItem) {
  const type = timelineItem.getIn(['eventData', 'type']);
  const sources = timelineItem.getIn(['eventData', 'sources']);
  const trackerKey = timelineItem.getIn(['eventData', 'tracker_key']);
  if (type === EMAIL_OPEN || type === EMAIL_CLICK) {
    return sources.reduce((map, source) => {
      const timestamp = source.get('timestamp');
      const id = ImmutableMap({
        id: `${type}-${timestamp}`,
        eventType: EVENT_SIDEKICK,
        trackerKey
      });
      const key = getMonthKey(timelineItem.set('timestamp', timestamp));
      return map.update(key, (timelineItemSet = ImmutableSet()) => {
        return timelineItemSet.add(id);
      });
    }, ImmutableMap());
  }
  return null;
}
function shouldFilterOutMarketingEmail(timelineItem) {
  return timelineItem.getIn(['eventData', 'cosEmail']) && timelineItem.hasIn(['eventData', 'obsoletedBy']) || timelineItem.getIn(['eventData', 'appName']) === 'BatchTest';
}
function handleEngagementUpdate(state, engagementsMap) {
  if (!engagementsMap || !engagementsMap.size) {
    return state;
  }
  const updatedEngagements = getUpdatedEngagements(engagementsMap);
  updatedEngagements.forEach(engagement => {
    let engagementDataMap = getId(engagement);
    const key = getMonthKey(engagement);

    // nothing has changed, return existing state
    if (state.hasIn([key, engagementDataMap])) {
      return state;
    }

    // check to see if the month has changed
    const oldKey = state.findKey(oldMonthKey => {
      if (typeof oldMonthKey.some !== 'function') {
        return false;
      }
      return oldMonthKey.some(engagementToCheck => engagementToCheck.get('id') === engagementDataMap.get('id'));
    });
    if (!oldKey) {
      return state;
    }

    // The eventType may be missing from the updated engagement response
    // Without it, and update may cause the engagement to disappear from the timeline
    // https://hubspot.slack.com/archives/C01BN1QJWSD/p1617904611061300?thread_ts=1617889604.040800&cid=C01BN1QJWSD
    if (!engagementDataMap.get('eventType')) {
      const timelineItemsSet = state.get(oldKey);
      const prevEngagementDataMap = timelineItemsSet.find(item => item.get('id') === engagementDataMap.get('id'));
      const eventType = get('eventType', prevEngagementDataMap);
      engagementDataMap = engagementDataMap.set('eventType', eventType);
    }

    // month key changed, remove engagementDataMap from the old month set, add it to the new one
    state = state.update(oldKey, timelineItemsSet => {
      return timelineItemsSet.filter(engagementToCheck => engagementToCheck.get('id') !== engagementDataMap.get('id'));
    }).update(key, (timelineItemsSet = ImmutableSet()) => {
      return timelineItemsSet.add(engagementDataMap);
    });
    return state;
  });
  return state;
}
export default defineKeyStore({
  namespace: 'TIMELINE_MONTHS',
  idIsValid(id) {
    return typeof id === 'string';
  }
}).defineResponseTo([ADD_SAMPLE_TIMELINE_ITEMS, FETCH_TIMELINE_ITEMS_SUCCESS, POLLING_FETCH_TIMELINE_ITEMS_SUCCESS], (state, data) => {
  const timelineItems = getTimelineItems(data);
  if (!timelineItems || !timelineItems.size) {
    return state;
  }
  timelineItems.forEach(timelineItem => {
    if (shouldFilterOutMarketingEmail(timelineItem)) {
      return;
    }

    // CRM-11854 - supress DEPRECATED_PAGEVIEW events
    if (timelineItem.get('etype') === 'eventPageView' && !timelineItem.getIn(['eventData', 'firstActivities'])) {
      return;
    }
    if (timelineItem.get('etype') === EVENT_SIDEKICK) {
      state = state.mergeDeep(getSidekickEvents(timelineItem));
    } else {
      const id = getId(timelineItem);
      const key = getMonthKey(timelineItem);
      const oldKey = state.findKey(timelineItemsSet => timelineItemsSet.includes(id));
      if (oldKey && oldKey !== key) {
        state = state.update(oldKey, timelineItemsSet => {
          return timelineItemsSet.delete(id);
        });
      }
      state = state.update(key, (timelineItemsSet = ImmutableSet()) => {
        return timelineItemsSet.add(id);
      });
    }
  });
  if (!data.sampleEvents && data !== null && data !== void 0 && data.get('originalPayload')) {
    var _data$get, _data$get2;
    state = state.set('subjectId', (_data$get = data.get('originalPayload')) === null || _data$get === void 0 ? void 0 : _data$get.get('subjectId'));
    state = state.set('objectType', normalizeTypeId((_data$get2 = data.get('originalPayload')) === null || _data$get2 === void 0 ? void 0 : _data$get2.get('objectType')));
  }
  return state;
}).defineResponseTo(DELETE_TIMELINE_ENGAGEMENT, (state, {
  engagement
}) => {
  const engagementDataMap = getId(engagement);
  const key = getMonthKey(engagement);
  state = state.update(key, (timelineItemsSet = ImmutableSet()) => {
    return timelineItemsSet.delete(engagementDataMap);
  });
  return state;
}).defineResponseTo(ADD_ENGAGEMENT, (state, {
  engagement
}) => {
  const engagementDataMap = getId(engagement);
  const key = getMonthKey(engagement);
  state = state.update(key, (timelineItemsSet = ImmutableSet()) => {
    return timelineItemsSet.add(engagementDataMap);
  });
  return state;
}).defineResponseTo(CHECK_SUBJECT_AND_ADD_ENGAGEMENT, (state, {
  engagement,
  subjectId,
  objectType
}) => {
  const normalizedObjectType = normalizeTypeId(objectType);
  if (subjectId !== state.get('subjectId') || normalizedObjectType !== state.get('objectType')) {
    return state;
  }
  const engagementDataMap = getId(engagement);
  const key = getMonthKey(engagement);
  state = state.update(key, (timelineItemsSet = ImmutableSet()) => {
    return timelineItemsSet.add(engagementDataMap);
  });
  return state;
}).defineResponseTo(ENGAGEMENTS_UPDATED, (state, engagementsMap) => {
  return handleEngagementUpdate(state, engagementsMap);
}).defineResponseTo(TASKS_AND_ENGAGEMENTS_UPDATED, (state, {
  engagementsMap
}) => {
  return handleEngagementUpdate(state, engagementsMap);
}).defineResponseTo(FETCHING_INITIAL_TIMELINE_EVENTS_STARTED, () => {
  return ImmutableMap();
}).defineResponseTo(EMAIL_TRACKING_EVENT_DELETED, (state, {
  timelineItem,
  trackerKey
}) => {
  if (!timelineItem && !trackerKey) {
    return state;
  }
  if (timelineItem) {
    // delete individual tracking activity
    const id = timelineItem.getIn(['eventData', 'id']);
    const key = `${getMonthTimestamp(timelineItem)}`;
    return state.update(key, (timelineItemsSet = ImmutableSet()) => {
      return timelineItemsSet.filterNot(event => event.get('id') === id);
    });
  }

  // delete all tracking activities with the given trackerKey
  return state.map(events => {
    return events.filterNot(evt => {
      return evt.get('trackerKey') === trackerKey;
    });
  });
}).register();