import { useEffect } from 'react';
let wasHidden = document.visibilityState === 'hidden';
const useVisibilityChange = () => {
  useEffect(() => {
    const callback = () => {
      if (document.visibilityState === 'hidden' && !wasHidden) {
        wasHidden = true;
      }
    };
    document.addEventListener('visibilitychange', callback);
    if (wasHidden) {
      document.removeEventListener('visibilitychange', callback);
    }
    return () => {
      document.removeEventListener('visibilitychange', callback);
    };
  });
};

// Track how many times we've rendered this location in this app
const locationRenderCount = new Map();
const getKey = (location, loggingTag) => `${location}-${loggingTag}`;
export function getIsFirstLoad(location, loggingTag) {
  const key = getKey(location, loggingTag);
  return String(locationRenderCount.get(key) === 1);
}
export function getWasHidden() {
  return (wasHidden ||
  // guard against race condition - user may have switched tabs before `addEventListener` call
  document.visibilityState === 'hidden').toString();
}
export function useIncrementLocationCount(location, loggingTag) {
  useVisibilityChange();
  useEffect(() => {
    const key = `${location}-${loggingTag}`;
    locationRenderCount.set(key, (locationRenderCount.get(key) || 0) + 1);
  }, [location, loggingTag]);
}