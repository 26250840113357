import { Loadable } from 'foundations-components/transitional/utils/Loadable';
import Raven from 'raven-js';
const PipelineApprovalBannerAsync = Loadable({
  loader: () => import( /* webpackChunkName: "PipelineApprovalsBanner" */
  './PipelineApprovalBanner'),
  LoadingComponent: () => null,
  ErrorComponent: ({
    error
  }) => {
    Raven.captureException(error);
    console.error(error);
    return null;
  }
});
export default PipelineApprovalBannerAsync;