import { AppRenderApplicationStart, AppStart, BeginningOfTime, SubjectHightlightReady, MountProfileContainer, RecordRouteMounted, RightSidebarLoaded, TimelineLoaded, PortalAndUserAgeLoaded, ProfileMounted, StartJS, I18nLoadStart, I18nLoadEnd, I18nRegisterStart, I18nRegisterEnd, EarlyI18nLoadStart, EarlyI18nLoadEnd, CrmRecordLeftInitialCardsReady, CrmRecordLeftLocationReady, CrmRecordMiddleInitialCardsReady, CrmRecordMiddleLocationReady, CrmRecordRightLocationReady, CrmRecordRightInitialCardsReady, SetupFetched, SetupLoaded } from '../../marks/crm-records-ui/performanceMarks';
export { AppStartToRenderApplication, LoadBaseJS, LoadHTML, UntilAppStart } from '../standard/performanceMeasurements';
import { EvaluateJS as StandardEvaluateJS } from '../standard/performanceMeasurements';

/**
{
  name: REQUIRED: String - Measurement name,
  start: REQUIRED: PerformanceMark,
  end: REQUIRED: PerformanceMark,
}
 */

export const AppStartToRecordRoute = {
  name: 'AppStartToRecordRoute',
  start: AppStart,
  end: RecordRouteMounted
};
export const RenderApplicationToRecordRoute = {
  name: 'RenderApplicationToRecordRoute',
  start: AppRenderApplicationStart,
  end: RecordRouteMounted
};
export const RenderApplicationToProfileMounted = {
  name: 'RenderApplicationToProfileMounted',
  start: AppRenderApplicationStart,
  end: ProfileMounted
};
export const RecordRouteMountedToProfileMounted = {
  name: 'RecordRouteMountedToProfileMounted',
  start: RecordRouteMounted,
  end: ProfileMounted
};
export const ProfileContainerToRightSidebar = {
  name: 'ProfileContainerToRightSidebar',
  start: MountProfileContainer,
  end: RightSidebarLoaded
};
export const ProfileContainerToTimelineSuccess = {
  name: 'ProfileContainerToTimelineSuccess',
  start: MountProfileContainer,
  end: TimelineLoaded
};
export const RenderApplicationToProfileContainer = {
  name: 'RenderApplicationToProfileContainer',
  start: AppRenderApplicationStart,
  end: MountProfileContainer
};
export const ProfileMountedToRightSidebar = {
  name: 'ProfileMountedToRightSidebar',
  start: ProfileMounted,
  end: RightSidebarLoaded
};
export const ProfileMountedToTimelineSuccess = {
  name: 'ProfileMountedToTimelineSuccess',
  start: ProfileMounted,
  end: TimelineLoaded
};
export const ProfileContainerToHightlightDetails = {
  name: 'ProfileContainerToHightlightDetails',
  start: MountProfileContainer,
  end: SubjectHightlightReady
};
export const ProfileMountedToHightlightDetails = {
  name: 'ProfileMountedToHightlightDetails',
  start: ProfileMounted,
  end: SubjectHightlightReady
};
export const ProfileContainerMountedToProfileMounted = {
  name: 'ProfileContainerMountedToProfileMounted',
  start: MountProfileContainer,
  end: ProfileMounted
};
export const EvaluateJS = {
  name: StandardEvaluateJS.name,
  start: StartJS,
  end: PortalAndUserAgeLoaded
};
export const TimeToStartJS = {
  name: 'TimeUntilStartJS',
  start: BeginningOfTime,
  end: StartJS
};
export const TimeToDownloadTranslations = {
  name: 'TimeToDownloadTranslations',
  start: I18nLoadStart,
  end: I18nLoadEnd
};
export const TimeToRegisterTranslations = {
  name: 'TimeToRegisterTranslations',
  start: I18nRegisterStart,
  end: I18nRegisterEnd
};
export const TimeToDownloadEarlyTranslations = {
  name: 'TimeToDownloadEarlyTranslations',
  start: EarlyI18nLoadStart,
  end: EarlyI18nLoadEnd
};
export const RecordLeftLocationReadyToInitialCardsReady = {
  name: 'RecordLeftLocationReadyToInitialCardsReady',
  start: CrmRecordLeftLocationReady,
  end: CrmRecordLeftInitialCardsReady
};
export const RecordMiddleLocationReadyToInitialCardsReady = {
  name: 'RecordMiddleLocationReadyToInitialCardsReady',
  start: CrmRecordMiddleLocationReady,
  end: CrmRecordMiddleInitialCardsReady
};
export const RecordRightLocationReadyToInitialCardsReady = {
  name: 'RecordRightLocationReadyToInitialCardsReady',
  start: CrmRecordRightLocationReady,
  end: CrmRecordRightInitialCardsReady
};
export const SetupLoadedToSetupFetched = {
  name: 'SetupLoadedToSetupFetched',
  start: SetupLoaded,
  end: SetupFetched
};