export const CARD_FORMATTER_IFRAME_LOOKUP_KEYS = {
  COLLAPSIBLE_CARD_FORMATTER_WITH_API: 'CollapsibleCardFormatterWithAPI',
  COLLAPSIBLE_CARD_FORMATTER: 'CollapsibleCardFormatter',
  COLLAPSIBLE_LIST_CARD_FORMATTER: 'CollapsibleListCardFormatter',
  EXTERNAL_TITLE_CARD_FORMATTER: 'ExternalTitleCardFormatter',
  FULL_CONTAINER_CARD_FORMATTER: 'FullContainerCardFormatter',
  GRID_CARD_FORMATTER: 'GridCardFormatter',
  TILE_CARD_FORMATTER: 'TileCardFormatter'
};
export const CARD_ACTION_FORMATTER_IFRAME_LOOKUP_KEYS = {
  CARD_ACTION_BUTTON_LIST: 'CardActionButtonList',
  LINK_CARD_ACTION_BUTTON_LIST: 'LinkCardActionButtonList'
};