'use es6';

import keyMirror from 'react-utils/keyMirror';
export default keyMirror({
  APP_STARTED: null,
  ACTIVITIES_HISTORY_REQUESTED: null,
  ACTIVITIES_HISTORY_RECEIVED: null,
  ACTIVITIES_HISTORY_FAILED: null,
  ACTIVITY_SUMMARY_CARD_CONFIGS_REQUESTED: null,
  ACTIVITY_SUMMARY_CARD_CONFIGS_RECEIVED: null,
  ACTIVITY_SUMMARY_CARD_CONFIGS_FAILED: null,
  ASSIGNMENT_HISTORY_REQUESTED: null,
  ASSIGNMENT_HISTORY_RECEIVED: null,
  ASSIGNMENT_HISTORY_FAILED: null,
  ASSIGNMENT_HISTORY_RESET: null,
  ASSIGNMENT_OWNER_REQUESTED: null,
  ASSIGNMENT_OWNER_RECEIVED: null,
  ASSIGNMENT_OWNER_FAILED: null,
  ASSOCIATED_COMPANIES_REQUESTED: null,
  ASSOCIATED_COMPANIES_RECEIVED: null,
  ASSOCIATED_COMPANIES_FAILED: null,
  ASSOCIATED_COMPANIES_BATCH_REQUESTED: null,
  ASSOCIATED_COMPANIES_BATCH_RECEIVED: null,
  ASSOCIATED_COMPANIES_BATCH_FAILED: null,
  ASSOCIATED_COMPANIES_BATCH_CHANGE_REQUESTED: null,
  ASSOCIATED_COMPANIES_BATCH_CHANGE_RECEIVED: null,
  ASSOCIATED_COMPANIES_BATCH_CHANGE_FAILED: null,
  ASSOCIATED_COMPANIES_REASONS_REQUESTED: null,
  ASSOCIATED_COMPANIES_REASONS_RECEIVED: null,
  ASSOCIATED_COMPANIES_REASONS_FAILED: null,
  BIZOPS_COMPANY_REQUESTED: null,
  BIZOPS_COMPANY_RECEIVED: null,
  BIZOPS_COMPANY_FAILED: null,
  CRM_DEAL_PRIMARY_HUB_ID_REQUESTED: null,
  CRM_DEAL_PRIMARY_HUB_ID_RECEIVED: null,
  CRM_DEAL_PRIMARY_HUB_ID_FAILED: null,
  CUSTOMER_SUMMARY_CARD_CONFIGS_REQUESTED: null,
  CUSTOMER_SUMMARY_CARD_CONFIGS_RECEIVED: null,
  CUSTOMER_SUMMARY_CARD_CONFIGS_FAILED: null,
  DOMAIN_CONTROL_INFO_REQUESTED: null,
  DOMAIN_CONTROL_INFO_RECEIVED: null,
  DOMAIN_CONTROL_INFO_FAILED: null,
  DOMAIN_ASSIGN_OWNER_RECEIVED: null,
  DOMAIN_ASSIGNMENT_CHANGE_REQUESTED: null,
  DOMAIN_ASSIGNMENT_CHANGE_RECEIVED: null,
  DOMAIN_ASSIGNMENT_CHANGE_FAILED: null,
  DOMAIN_IBDR_ASSIGNMENT_CHANGE_RECEIVED: null,
  DOMAIN_PARTNER_SPECIALIST_ASSIGNMENT_CHANGE_REQUESTED: null,
  DOMAIN_PARTNER_SPECIALIST_ASSIGNMENT_CHANGE_RECEIVED: null,
  DOMAIN_PARTNER_SPECIALIST_ASSIGNMENT_CHANGE_FAILED: null,
  ENGAGEMENT_CONTACTS_REQUESTED: null,
  ENGAGEMENT_CONTACTS_RECEIVED: null,
  ENGAGEMENT_CONTACTS_FAILED: null,
  ENGAGEMENT_OWNERS_REQUESTED: null,
  ENGAGEMENT_OWNERS_RECEIVED: null,
  ENGAGEMENT_OWNERS_FAILED: null,
  ENGAGEMENTS_REQUESTED: null,
  ENGAGEMENTS_RECEIVED: null,
  ENGAGEMENTS_FAILED: null,
  HUB_ACCOUNT_PLAN_REQUESTED: null,
  HUB_ACCOUNT_PLAN_RECEIVED: null,
  HUB_ACCOUNT_PLAN_FAILED: null,
  MIGRATION_RENEWAL_STATUS_REQUESTED: null,
  MIGRATION_RENEWAL_STATUS_RECEIVED: null,
  MIGRATION_RENEWAL_STATUS_FAILED: null,
  HUBLET_REQUESTED: null,
  HUBLET_RECEIVED: null,
  HUBLET_FAILED: null,
  HUB_OWNERS_REQUESTED: null,
  HUB_OWNERS_RECEIVED: null,
  HUB_OWNERS_FAILED: null,
  HUB_ROLLUP_REQUESTED: null,
  HUB_ROLLUP_RECEIVED: null,
  HUB_ROLLUP_FAILED: null,
  HUBS_BY_USER_REQUESTED: null,
  HUBS_BY_USER_RECEIVED: null,
  HUBS_BY_USER_FAILED: null,
  HUBSPOT_USER_REQUESTED: null,
  HUBSPOT_USER_RECEIVED: null,
  HUBSPOT_USER_FAILED: null,
  HUBSPOTTER_REQUESTED: null,
  HUBSPOTTER_RECEIVED: null,
  HUBSPOTTER_FAILED: null,
  HUB_SUMMARIES_ACTIVE_COUNT_REQUESTED: null,
  HUB_SUMMARIES_ACTIVE_COUNT_RECEIVED: null,
  HUB_SUMMARIES_ACTIVE_COUNT_FAILED: null,
  HUB_SUMMARIES_REQUESTED: null,
  HUB_SUMMARIES_RECEIVED: null,
  HUB_SUMMARIES_FAILED: null,
  LAST_TOUCH_REQUESTED: null,
  LAST_TOUCH_RECEIVED: null,
  LAST_TOUCH_FAILED: null,
  MARK_BAD_FIT_REQUESTED: null,
  MARK_BAD_FIT_RECEIVED: null,
  MARK_BAD_FIT_FAILED: null,
  MORE_ASSOCIATED_COMPANIES_REQUESTED: null,
  MORE_ASSOCIATED_COMPANIES_RECEIVED: null,
  MORE_ASSOCIATED_COMPANIES_FAILED: null,
  RECYCLE_DOMAIN_REQUESTED: null,
  RECYCLE_DOMAIN_RECEIVED: null,
  RECYCLE_DOMAIN_FAILED: null,
  RECYCLE_IBDR_OWNER_RECEIVED: null,
  RECYCLE_OWNER_RECEIVED: null,
  RECYCLE_PS_OWNER_REQUESTED: null,
  RECYCLE_PS_OWNER_RECEIVED: null,
  RECYCLE_PS_OWNER_FAILED: null,
  RELATED_CONTACTS_REQUESTED: null,
  RELATED_CONTACTS_RECEIVED: null,
  RELATED_CONTACTS_FAILED: null,
  REMOVE_BAD_FIT_RECEIVED: null,
  DING_DATA_REFRESHED: null,
  DING_FORM_SAVED: null,
  DOMAIN_INFO_REFRESHED: null,
  PARTNER_COLLAB_REQUEST_CREATED: null,
  PARTNER_COLLAB_REQUEST_APPROVAL_UPDATED: null,
  PARTNER_COLLAB_ELIGIBILITY_UPDATED: null,
  PORTALS_REQUESTED: null,
  PORTALS_RECEIVED: null,
  PORTALS_FAILED: null,
  PRODUCT_LINE_OWNERSHIPS_REQUESTED: null,
  PRODUCT_LINE_OWNERSHIPS_RECEIVED: null,
  PRODUCT_LINE_OWNERSHIPS_FAILED: null,
  PRODUCT_LINES_REQUESTED: null,
  PRODUCT_LINES_RECEIVED: null,
  PRODUCT_LINES_FAILED: null,
  PRODUCT_OUTCOMES_EXTENDED_REQUESTED: null,
  PRODUCT_OUTCOMES_EXTENDED_RECEIVED: null,
  PRODUCT_OUTCOMES_EXTENDED_FAILED: null,
  RECENT_CONVERSION_HISTORY_REQUESTED: null,
  RECENT_CONVERSION_HISTORY_RECEIVED: null,
  RECENT_CONVERSION_HISTORY_FAILED: null,
  RECENT_CONVERSIONS_CLEAR: null,
  RECENT_CONVERSIONS_REQUESTED: null,
  RECENT_CONVERSIONS_RECEIVED: null,
  RECENT_CONVERSIONS_FAILED: null,
  RENEWAL_SUMMARY_SUBSCRIPTIONS_CLEAR: null,
  RENEWAL_SUMMARY_SUBSCRIPTIONS_REQUESTED: null,
  RENEWAL_SUMMARY_SUBSCRIPTIONS_RECEIVED: null,
  RENEWAL_SUMMARY_SUBSCRIPTIONS_FAILED: null,
  RENEWAL_SUMMARY_CONTRACT_CHANGE_MRR_INFO_CLEAR: null,
  RENEWAL_SUMMARY_CONTRACT_CHANGE_MRR_INFO_REQUESTED: null,
  RENEWAL_SUMMARY_CONTRACT_CHANGE_MRR_INFO_RECEIVED: null,
  RENEWAL_SUMMARY_CONTRACT_CHANGE_MRR_INFO_FAILED: null,
  SUCCESS_PREDICTION_REQUESTED: null,
  SUCCESS_PREDICTION_RECEIVED: null,
  SUCCESS_PREDICTION_FAILED: null,
  TOOLS_LINKS_REQUESTED: null,
  TOOLS_LINKS_RECEIVED: null,
  TOOLS_LINKS_FAILED: null,
  TRIGGERS_REQUESTED: null,
  TRIGGERS_RECEIVED: null,
  TRIGGERS_FAILED: null,
  QUOTE_CARD_REFRESHED: null,
  QUOTE_CARD_UPDATE_QUOTE_NAME: null,
  QUOTE_GUEST_PURCHASERS_AND_ADMINS_REQUESTED: null,
  QUOTE_GUEST_PURCHASERS_AND_ADMINS_RECEIVED: null,
  QUOTE_GUEST_PURCHASERS_AND_ADMINS_FAILED: null,
  QUOTE_DETAILS_REQUESTED: null,
  QUOTE_DETAILS_RECEIVED: null,
  QUOTE_DETAILS_FAILED: null,
  QUOTE_OTP_FLOW_STATUS_REQUESTED: null,
  QUOTE_OTP_FLOW_STATUS_RECEIVED: null,
  QUOTE_OTP_FLOW_STATUS_FAILED: null
});