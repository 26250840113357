module.exports = {
  "growthPaymentsPageView": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "screen": "string",
      "hasCreateAccess": {
        "type": "boolean",
        "isOptional": true
      },
      "value": {
        "type": "string",
        "isOptional": true
      },
      "productLevel": {
        "type": [
          "pro",
          "none",
          "enterprise",
          "starter"
        ],
        "isOptional": true
      },
      "chargesEnabled": {
        "type": "boolean",
        "isOptional": true
      },
      "payoutsEnabled": {
        "type": "boolean",
        "isOptional": true
      },
      "referrerUrl": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "payments-home",
    "version": "1"
  },
  "hubSpotApplicationFormInteraction": {
    "name": "hubspot-application-form-interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "focus",
        "blur",
        "click",
        "select"
      ],
      "field": {
        "type": "string"
      },
      "value": {
        "type": "string",
        "isOptional": true
      },
      "location": {
        "type": "string",
        "isOptional": true
      },
      "referrerUrl": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "payments-home",
    "version": "1"
  },
  "paymentSettingsInteraction": {
    "name": "payment-settings-interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "goToPaymentsEnrollmentViewed",
          "goToPaymentsEnrollmentClicked",
          "setUpHubspotPaymentsViewed",
          "goToProcessorPickerViewed",
          "setUpHubspotPaymentsClicked",
          "goToProcessorPickerClicked",
          "viewPricingDetailsClicked",
          "viewPriceComparisonTableClicked",
          "priceComparisonTableModalCloseClicked",
          "priceComparisonTableLinkClicked",
          "goToZeroStateButtonClicked",
          "setUpStripePaymentsViewed",
          "setUpStripePaymentsClicked",
          "HSApplicationGoBackClicked",
          "HSApplicationNextStepClicked",
          "BYOSApplicationNextStepClicked",
          "BYOSJoinWaitlistClicked",
          "closeHSApplicationClicked",
          "closeProcessorPickerClicked",
          "closeBYOSApplicationClicked",
          "openFaq",
          "cancelModalGoBackButtonClicked",
          "cancelModalConfirmButtonClicked",
          "openCancelSurvey",
          "noPermissionsModalViewed",
          "submitCancelSurvey",
          "rejectCancelSurvey",
          "scrollOnEmptyState",
          "emptyStateLinkClick",
          "emptyStateButtonClick",
          "quoteScroll",
          "videoPlay",
          "clickBackToPaymentsOverviewButton",
          "stripeApplicationSuccessModalButtonClicked",
          "fullApplicationSubmitted",
          "stripeApplicationFallbackRedirectButtonClicked",
          "showFallbackModal",
          "stripeBusinessOwnerClickAdd",
          "stripeBusinessOwnerClickExisting",
          "stripeBusinessOwnerClickEdit",
          "stripeBusinessOwnerClickRemove",
          "stripeBusinessOwnerUpdate",
          "stripeBusinessOwnerUpdateAndAddAnother",
          "stripeBusinessOwnerEditCancelClicked",
          "stripeBusinessOwnerEditDeleteClicked",
          "applicationSubmitButtonClicked",
          "contactSidePanelButtonClicked",
          "contactSidePanelInputInteraction",
          "contactSidePanelSubmissionSuccess",
          "gotoGuideButtonClicked",
          "gotoUpgradeButtonClicked",
          "gotoZeroStateButtonClicked",
          "setupButtonIneligibleCountryClicked",
          "processorPickerLinkClick"
        ]
      },
      "productLevel": {
        "type": [
          "pro",
          "none",
          "enterprise",
          "starter"
        ],
        "isOptional": true
      },
      "value": {
        "type": "string",
        "isOptional": true
      },
      "values": {
        "type": "array",
        "isOptional": true
      },
      "page": {
        "type": "string",
        "isOptional": true
      },
      "location": {
        "type": "string",
        "isOptional": true
      },
      "referrerUrl": {
        "type": "string",
        "isOptional": true
      },
      "section": {
        "type": "string",
        "isOptional": true
      },
      "index": {
        "type": "number",
        "isOptional": true
      },
      "fields": {
        "type": "array",
        "isOptional": true
      },
      "customFormFields": {
        "type": "array",
        "isOptional": true
      },
      "missingFeatures": {
        "type": "array",
        "isOptional": true
      },
      "pendingVerificationRequirements": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "payments-home",
    "version": "1"
  },
  "commerceSurveyInteraction": {
    "name": "commerce-survey-interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "surveyButtonClicked",
          "surveyAccessPointViewed",
          "surveyCompleted",
          "surveyClosed",
          "surveyQuestionViewed",
          "surveyAnswerSelected"
        ]
      },
      "location": {
        "type": [
          "processorPicker",
          "commerceZeroState",
          "learnAboutFeesModal",
          "processorComparisonTable"
        ],
        "isOptional": true
      },
      "surveyType": {
        "type": "string",
        "isOptional": true
      },
      "question": {
        "type": "string",
        "isOptional": true
      },
      "questionNumber": {
        "type": "number",
        "isOptional": true
      },
      "answers": {
        "type": "array",
        "isOptional": true
      },
      "referrerUrl": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "payments-home",
    "version": "1"
  }
};