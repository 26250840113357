import { LOADING_DEFAULT_COLOR, LOADING_INACTIVE_COLOR } from 'HubStyleTokens/theme';
import styled, { keyframes } from 'styled-components';

/** Code taken from https://git.hubteam.com/HubSpot/UIComponents/blob/master/UIComponents/static-3.5353/js/loading/UILoadingDots.tsx
 * I made a few changes to ensure the loading dot can appear on the same line as the text
 */

const loadingColorAnimation = keyframes(["25%{background-color:", ";}75%{background-color:", ";}"], LOADING_INACTIVE_COLOR, LOADING_INACTIVE_COLOR);
const loadingAnimation = keyframes(["0%,100%{transform:translateY(0);}25%{transform:translateY(.25em);}50%{transform:translateY(0);}75%{transform:translateY(-.25em);}"]);
const Dot = styled.span.withConfig({
  displayName: "LoadingDot__Dot",
  componentId: "sc-1bgaz3u-0"
})(["position:relative;background-color:currentColor;color:", ";display:inline-block;border-radius:50%;height:0.7em;width:0.7em;margin:0 4px;animation:", ",", ";animation-duration:1s;animation-timing-function:linear;animation-iteration-count:infinite;&:not(:last-child){margin-right:0.75em;}&:nth-child(2){animation-delay:-0.66s;}&:nth-child(3){animation-delay:-0.33s;}"], LOADING_DEFAULT_COLOR, loadingAnimation, loadingColorAnimation);
export default Dot;