import { gate } from 'hub-http/gates';
export const LOGGED_CALL_INTERACTION_GATE = 'CRM:ActivityCreator:LoggedCallInteraction';
export const LOGGED_EMAIL_INTERACTION_GATE = 'CRM:ActivityCreator:LoggedEmailInteraction';
export const LOGGED_MEETING_INTERACTION_GATE = 'CRM:ActivityCreator:LoggedMeetingsInteraction';
export const LOGGED_SMS_INTERACTION_GATE = 'CRM:ActivityCreator:LoggedSMSInteraction';
export const LOGGED_POSTAL_MAIL_INTERACTION_GATE = 'CRM:ActivityCreator:LoggedPostalMailInteraction';
export const LOGGED_WHATS_APP_INTERACTION_GATE = 'CRM:ActivityCreator:LoggedWhatsAppInteraction';
export const LOGGED_LINKED_IN_MESSAGE_INTERACTION_GATE = 'CRM:ActivityCreator:LoggedLinkedInMessageInteraction';
export const NOTE_INTERACTION_GATE = 'CRM:ActivityCreator:NoteInteraction';
export const NOTES_EDIT_SEAT_GATE = 'CRM:ActivityCreator:NotesEditSeat';
export const TASK_INTERACTION_GATE = 'CRM:ActivityCreator:TaskInteraction';
export const ACTIVITY_HISTORY_LIMIT_GATE = gate('CRM:Events:ActivityHistoryLimit');