import { useEligibilityBinding } from 'growth-payments-core/eligibility/eligibilityBinding';
import { hasCompletedStripe, calculateKYCStatus } from 'growth-payments-core/eligibility/utils';
import { useHasPaymentLinkExcludingDeletedBinding } from '../../api/SalesCheckoutApi';
export const PaymentLinkStatus = {
  LOADING: 'LOADING',
  PRE_ENROLL_PRE_TOOL: 'PRE_ENROLL_PRE_TOOL',
  PRE_ENROLL_POST_TOOL: 'PRE_ENROLL_POST_TOOL',
  POST_ENROLL_PRE_TOOL: 'POST_ENROLL_PRE_TOOL',
  POST_ENROLL_POST_TOOL: 'POST_ENROLL_POST_TOOL'
};
const usePaymentLinkStatus = () => {
  const [eligibility] = useEligibilityBinding();
  const [hasPaymentLink] = useHasPaymentLinkExcludingDeletedBinding();
  const hasCompletedKyc = hasCompletedStripe(calculateKYCStatus(eligibility && eligibility.underwritingStatus));
  if (hasPaymentLink === undefined || hasPaymentLink === null) {
    return PaymentLinkStatus.LOADING;
  }
  if (hasCompletedKyc) {
    return hasPaymentLink ? PaymentLinkStatus.POST_ENROLL_POST_TOOL : PaymentLinkStatus.POST_ENROLL_PRE_TOOL;
  } else {
    return hasPaymentLink ? PaymentLinkStatus.PRE_ENROLL_POST_TOOL : PaymentLinkStatus.PRE_ENROLL_PRE_TOOL;
  }
};
export default usePaymentLinkStatus;