module.exports = {
  "mode": "compressed",
  "staticDomainPrefix": "//static.hsappstatic.net",
  "bender": {
    "depVersions": {
      "content-icons-ui": "static-1.1790"
    },
    "depPathPrefixes": {
      "content-icons-ui": "/content-icons-ui/static-1.1790"
    },
    "project": "content-icons-ui",
    "staticDomain": "//static.hsappstatic.net",
    "staticDomainPrefix": "//static.hsappstatic.net"
  }
};