import { useCallback } from 'react';
import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
import { useSendCrmMessageTopic } from 'crm-message-bus/useSendCrmMessageTopic';
import { MISSING_PERMISSIONS, PERMISSIONS_ERROR } from '../types/ValidationError';
import { useFetchUserPermissionsForObject } from '../hooks/useFetchUserPermissionsForObject';
import { useUserInfo } from '../hooks/useUserInfo';
const getErrors = (userInfoQueryResults, userObjectPermissionsResults) => {
  var _userInfo$user$scopes;
  const {
    userInfo,
    error: userInfoError
  } = userInfoQueryResults;
  const {
    currentUserCanView,
    error: objectPermissionsError
  } = userObjectPermissionsResults;
  const scopes = (_userInfo$user$scopes = userInfo === null || userInfo === void 0 ? void 0 : userInfo.user.scopes) !== null && _userInfo$user$scopes !== void 0 ? _userInfo$user$scopes : [];
  const userHasSequencesAccess = scopes.includes('sequences-access');
  if (userInfoError || objectPermissionsError) {
    return [PERMISSIONS_ERROR];
  }
  if (!currentUserCanView || !userHasSequencesAccess) {
    return [MISSING_PERMISSIONS];
  }
  return [];
};
export function useOpenSequenceEnrollmentModalAction({
  objectId,
  objectTypeId
}) {
  const userInfoQueryResults = useUserInfo();
  const userObjectPermissionsResults = useFetchUserPermissionsForObject({
    objectId,
    objectTypeId
  });
  const openSequenceEnrollmentModal = useSendCrmMessageTopic(TOPIC_NAMES.OPEN_SEQUENCE_ENROLLMENT_MODAL);
  const callback = useCallback(() => {
    openSequenceEnrollmentModal({
      objectTypeId,
      objectId
    });
  }, [openSequenceEnrollmentModal, objectId, objectTypeId]);
  const errors = getErrors(userInfoQueryResults, userObjectPermissionsResults);
  if (errors.length) {
    return {
      errors
    };
  }
  return {
    callback
  };
}