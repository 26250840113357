import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["margin", "marginBlock", "marginBlockEnd", "marginBlockStart", "marginInline", "marginInlineEnd", "marginInlineStart", "padding", "paddingBlock", "paddingBlockEnd", "paddingBlockStart", "paddingInline", "paddingInlineEnd", "paddingInlineStart"];
export const spacingMap = {
  '0': '0',
  initial: '0',
  inherit: '0',
  unset: '0',
  'space-xxxxs': '25',
  'space-xxxs': '50',
  'space-xxs': '100',
  'space-xs': '200',
  'space-sm': '300',
  space: '400',
  'space-md': '600',
  'space-lg': '800',
  'space-xl': '1000',
  'space-xxl': '1100',
  'space-xxxl': '1400',
  'space-xxxxl': '1500'
};
export const useSpacingProps = props => {
  const {
      margin,
      marginBlock,
      marginBlockEnd,
      marginBlockStart,
      marginInline,
      marginInlineEnd,
      marginInlineStart,
      padding,
      paddingBlock,
      paddingBlockEnd,
      paddingBlockStart,
      paddingInline,
      paddingInlineEnd,
      paddingInlineStart
    } = props,
    rest = _objectWithoutPropertiesLoose(props, _excluded);
  const spacingProps = {
    margin: margin ? spacingMap[margin] : undefined,
    marginBlock: marginBlock ? spacingMap[marginBlock] : undefined,
    marginBlockEnd: marginBlockEnd ? spacingMap[marginBlockEnd] : undefined,
    marginBlockStart: marginBlockStart ? spacingMap[marginBlockStart] : undefined,
    marginInline: marginInline ? spacingMap[marginInline] : undefined,
    marginInlineEnd: marginInlineEnd ? spacingMap[marginInlineEnd] : undefined,
    marginInlineStart: marginInlineStart ? spacingMap[marginInlineStart] : undefined,
    padding: padding ? spacingMap[padding] : undefined,
    paddingBlock: paddingBlock ? spacingMap[paddingBlock] : undefined,
    paddingBlockEnd: paddingBlockEnd ? spacingMap[paddingBlockEnd] : undefined,
    paddingBlockStart: paddingBlockStart ? spacingMap[paddingBlockStart] : undefined,
    paddingInline: paddingInline ? spacingMap[paddingInline] : undefined,
    paddingInlineEnd: paddingInlineEnd ? spacingMap[paddingInlineEnd] : undefined,
    paddingInlineStart: paddingInlineStart ? spacingMap[paddingInlineStart] : undefined
  };
  return {
    spacingProps,
    otherProps: rest
  };
};