import { KOALA, GREAT_WHITE } from 'HubStyleTokens/colors';
import styled, { keyframes } from 'styled-components';
const pulse = keyframes(["0%{background-color:", "}50%{background-color:", "}100%{background-color:", "}"], KOALA, GREAT_WHITE, KOALA);

// Skeleton Label
const SKELETON_TITLE_WIDTH = 100;
const SKELETON_TITLE_HEIGHT = 16;
export const SkeletonLabel = styled.div.withConfig({
  displayName: "PropertySkeleton__SkeletonLabel",
  componentId: "sc-1agvblg-0"
})(["display:inline-block;height:", "px;width:", "px;max-width:100%;border-radius:3px;margin-bottom:0px;animation:", " 2s ease-in-out infinite;"], SKELETON_TITLE_HEIGHT, SKELETON_TITLE_WIDTH, pulse);

// Export for height calculations
export const SKELETON_TITLE_TOTAL_HEIGHT = SKELETON_TITLE_HEIGHT;

// Skeleton Value
const SKELETON_VALUE_WIDTH = 240;
export const SKELETON_VALUE_HEIGHT = 16;
export const SKELETON_VALUE_MARGIN_TOP = 8;
export const SKELETON_VALUE_MARGIN_BOTTOM = 0;
export const SkeletonValue = styled.div.withConfig({
  displayName: "PropertySkeleton__SkeletonValue",
  componentId: "sc-1agvblg-1"
})(["display:inline-block;height:", "px;width:", "px;max-width:100%;border-radius:3px;margin-top:", "px;margin-bottom:", "px;animation:", " 2s ease-in-out infinite;"], SKELETON_VALUE_HEIGHT, ({
  width
}) => width !== undefined ? width : SKELETON_VALUE_WIDTH, ({
  marginTop
}) => marginTop !== undefined ? marginTop : SKELETON_VALUE_MARGIN_TOP, ({
  marginBottom
}) => marginBottom !== undefined ? marginBottom : SKELETON_VALUE_MARGIN_BOTTOM, pulse);

// Export for height calculations
export const SKELETON_VALUE_TOTAL_HEIGHT = SKELETON_VALUE_HEIGHT + SKELETON_VALUE_MARGIN_TOP + SKELETON_VALUE_MARGIN_BOTTOM;