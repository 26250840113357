import quickFetch from 'quick-fetch';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import * as ImmutableAPI from 'crm_data/api/ImmutableAPI';
import { fromJS, List, Map as ImmutableMap, Iterable } from 'immutable';
const API_BASE = 'timeline/v2/';
const INTEGRATION_API = 'integrators/timeline/v1/portal-installs/apps-with-event-types';
const INSTALLED_HUBSPOT_OWNED_INTEGRATIONS_API = 'integrators/v1/portal-installs/hubspot-owned';
const TIMELINE_TIMEOUT = 5000;
export const TIMELINE_ITEM_LIMIT = 20;
const orderedFromJS = json => fromJS(json, (__key, value) => Iterable.isKeyed(value) ? value.toOrderedMap() : value.toList());
const getUriFromApiConfig = (apiConfig, shouldUseObjectEndpoint) => {
  const objectType = apiConfig.get('objectType');
  const objectId = apiConfig.get('objectId');
  if (shouldUseObjectEndpoint) {
    return `${API_BASE}object/${encodeURIComponent(objectType)}/${objectId}`;
  }
  return `${API_BASE}${objectType}/${objectId}`;
};
export const getQuery = (apiConfig, passEventTypes = true) => {
  const query = {
    timeout: TIMELINE_TIMEOUT,
    limit: apiConfig.get('TIMELINE_ITEM_LIMIT') || TIMELINE_ITEM_LIMIT
  };
  if (passEventTypes) {
    query.t = apiConfig.get('eventOptions').toJS();
  }
  if (apiConfig.has('toDate')) {
    query.to = apiConfig.get('toDate');
  }

  // The only instance we use fromDate is for polling timeline requests
  if (apiConfig.has('fromDate')) {
    query.from = apiConfig.get('fromDate');
  }
  return query;
};
export function fetchTimeline(apiConfig, shouldUseObjectEndpoint) {
  const uri = `${getUriFromApiConfig(apiConfig, shouldUseObjectEndpoint)}?renderingRequested=true`;
  return ImmutableAPI.get(uri, getQuery(apiConfig), orderedFromJS).then(data => {
    // CRM-10769 - tmp fix, handle nextTimestamp === 0
    if (data.get('nextTimestamp') === 0) {
      return data.set('hasMore', false).remove('nextTimestamp');
    }
    return data;
  });
}
function setNewRelicCustomAttr(key, value) {
  if (window && window.newrelic && window.newrelic.setCustomAttribute) {
    window.newrelic.setCustomAttribute(key, value);
  }
}

/**
 *
 * @param {Map} apiConfig
 * @example apiConfig
 *
 *    Map({
 *      subjectId: '123456',
 *      objectType: 'CONTACT',
 *      TIMELINE_ITEM_LIMIT: 20,
 *      toDate: 456789012,
 *    })
 *
 * @description
 * The initial timeline fetch with error handling that's used
 * to fetch the first timeline events and update the timeline
 * quickFetch state
 *
 */
export function fetchTimelineDefaults(apiConfig, shouldUseObjectEndpoint) {
  /**
   * the "default" endpoint leaves it up to the BE to determine which event types to fetch for this object
   *
   * the "default" endpoint does not (yet) exist for the timeline/v2/object/<objectTypeId>/<subjectId> endpoint,
   * thus the backend returns ALL available events for the given object
   *
   */
  const uri = `${getUriFromApiConfig(apiConfig, shouldUseObjectEndpoint)}${shouldUseObjectEndpoint ? '' : '/default'}?renderingRequested=true`;
  const fallbackRequest = () => {
    return ImmutableAPI.get(uri, getQuery(apiConfig, false), orderedFromJS).then(data => data).catch(() => ImmutableMap({
      events: List(),
      nextTimestamp: new Date().valueOf(),
      failed: List(),
      timedOut: List(),
      hasMore: true,
      hasError: true
    }));
  };
  const earlyTimelineRequest = quickFetch.getRequestStateByName('timeline');
  if (earlyTimelineRequest && !earlyTimelineRequest.eventsRetrieved) {
    return new Promise((resolve, reject) => {
      earlyTimelineRequest.whenFinished(response => {
        setNewRelicCustomAttr('timelineEarlyRequest', 'succeeded');
        earlyTimelineRequest.eventsRetrieved = true;
        resolve(orderedFromJS(response));
      });
      earlyTimelineRequest.onError(() => {
        setNewRelicCustomAttr('timelineEarlyRequest', 'failed');
        earlyTimelineRequest.eventsRetrieved = true;
        return fallbackRequest().then(resolve, reject);
      });
    });
  }
  return fallbackRequest();
}
function fetchWithQuickFetch(quickFetchKey, url) {
  const fallbackRequest = () => ImmutableAPI.get(url, {}, orderedFromJS);
  const earlyRequest = quickFetch.getRequestStateByName(quickFetchKey);
  if (earlyRequest) {
    return new Promise((resolve, reject) => {
      earlyRequest.whenFinished(response => {
        quickFetch.removeEarlyRequest(quickFetchKey);
        resolve(orderedFromJS(response));
      });
      earlyRequest.onError(() => {
        return fallbackRequest().then(resolve, reject);
      });
    });
  }
  return fallbackRequest();
}
export function fetchIntegrationEvents() {
  return fetchWithQuickFetch('quickFetchIntegrationEvents', INTEGRATION_API);
}
export function fetchInstalledHubSpotOwnedIntegrations() {
  return fetchWithQuickFetch('quickFetchInstalledHubSpotOwnedIntegrations', INSTALLED_HUBSPOT_OWNED_INTEGRATIONS_API);
}

// TODO COBJECT RECORD: Switch to crm-search to support searching engagements
// associated with a cobject - https://git.hubteam.com/HubSpot/CRM-Issues/issues/6977
export function fetchTimelineByUsersAndTeams(body) {
  const url = 'contacts/search/v1/search/engagements/v3';
  return ImmutableAPI.post(url, body);
}

/* eslint-env commonjs */
// This temporary hack ensures module system compatibility.
// Read more at go/treeshaking
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: Globals aren't recommended, but as long as the `module` global is around we need this ignore directive or the global can break builds of dependents who don't have a definition for it
if (!!module && !!module.exports) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Globals aren't recommended, but as long as the `module` global is around we need this ignore directive or the global can break builds of dependents who don't have a definition for it
  module.exports.default = Object.assign({}, module.exports);
}