import CompactEventSkeleton from '../skeletons/CompactEventSkeleton';
import TimelineCardError from '../eventStructure/TimelineCardError';
import { Loadable } from 'foundations-components/transitional/utils/Loadable';
export function makeLoadableCard(loader, LoadingComponent = CompactEventSkeleton, ErrorComponent = TimelineCardError) {
  return Loadable({
    loader,
    LoadingComponent,
    ErrorComponent,
    delay: 0
  });
}