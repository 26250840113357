import { Loadable } from 'foundations-components/transitional/utils/Loadable';
import Raven from 'raven-js';
import RecordHighlightFullSkeleton from 'card-preview-highlight-lib/components/skeleton/RecordHighlightFullSkeleton';
const RecordHighlightWrapperAsync = Loadable({
  loader: () => import( /* webpackChunkName: "RecordHighlightWrapper" */
  /* webpackPreload: true */
  './RecordHighlightWrapper'),
  LoadingComponent: RecordHighlightFullSkeleton,
  ErrorComponent: ({
    error
  }) => {
    Raven.captureException(error);
    console.error(error);
    return null;
  }
});
export default RecordHighlightWrapperAsync;