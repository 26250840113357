import styled from 'styled-components';
import { getReferencePadding } from './ActionsMenu.helpers';
import { deepMerge } from '../../transitional/utils/deepMerge/deepMerge';
export const StyledContainer = styled.div.withConfig({
  displayName: "ActionsMenustyles__StyledContainer",
  componentId: "sc-13iv0w0-0"
})(({
  $styleOverrides
}) => {
  return deepMerge({
    inlineSize: '100%'
  }, $styleOverrides);
});
export const StyledReferenceWrapper = styled.div.withConfig({
  displayName: "ActionsMenustyles__StyledReferenceWrapper",
  componentId: "sc-13iv0w0-1"
})(({
  theme: _theme,
  $referenceSize,
  $referenceLayout,
  $marginStyles
}) => {
  const theme = _theme;
  const inlineSize = $referenceLayout === 'indicator-only' ? 'fit-content' : '100%';
  const blockSize = $referenceSize === 'default' ? theme.space[1000] : theme.space[800];
  return Object.assign({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    inlineSize,
    blockSize
  }, $marginStyles);
});
export const StyledReferenceIndicator = styled.div.withConfig({
  displayName: "ActionsMenustyles__StyledReferenceIndicator",
  componentId: "sc-13iv0w0-2"
})(({
  theme: _theme,
  $referenceSize
}) => {
  const theme = _theme;
  const {
    paddingBlock
  } = getReferencePadding(theme);
  const referenceHeight = $referenceSize === 'default' ? theme.space[1000] : theme.space[800];
  return {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    blockSize: `calc(${referenceHeight} - ${paddingBlock} * 2)`,
    borderRadius: theme.borderRadius[300],
    flexShrink: 0
  };
});
export const StyledReferenceText = styled.span.withConfig({
  displayName: "ActionsMenustyles__StyledReferenceText",
  componentId: "sc-13iv0w0-3"
})(({
  theme: _theme
}) => {
  const theme = _theme;
  return {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginInlineEnd: theme.space[200]
  };
});
export const StyledDownCaratIconWrapper = styled.span.withConfig({
  displayName: "ActionsMenustyles__StyledDownCaratIconWrapper",
  componentId: "sc-13iv0w0-4"
})(({
  $iconSize
}) => {
  return {
    display: 'flex',
    alignItems: 'center',
    opacity: 0.8,
    inlineSize: $iconSize,
    blockSize: $iconSize
  };
});
export const StyledMenu = styled.div.withConfig({
  displayName: "ActionsMenustyles__StyledMenu",
  componentId: "sc-13iv0w0-5"
})(({
  theme: _theme,
  $isReferenceHidden
}) => {
  const theme = _theme;
  return {
    visibility: $isReferenceHidden ? 'hidden' : 'visible',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    outline: 0,
    backgroundColor: theme.color['fill-surface-default'],
    borderRadius: theme.borderRadius[200],
    boxShadow: theme.boxShadow[300],
    zIndex: 1111
  };
});
export const StyledMenuOptionsWrapper = styled.div.withConfig({
  displayName: "ActionsMenustyles__StyledMenuOptionsWrapper",
  componentId: "sc-13iv0w0-6"
})(({
  theme: _theme
}) => {
  const theme = _theme;
  return {
    borderRadius: 'inherit',
    flexGrow: 1,
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: `${theme.color['fill-tertiary-pressed']} transparent`
  };
});
export const StyledMenuOption = styled.div.withConfig({
  displayName: "ActionsMenustyles__StyledMenuOption",
  componentId: "sc-13iv0w0-7"
})(({
  theme: _theme,
  $isActive,
  $disabled,
  $divider,
  $isSubheading
}) => {
  const theme = _theme;
  return Object.assign({
    paddingBlock: theme.space[300],
    paddingInline: theme.space[400],
    cursor: 'pointer',
    outline: 0
  }, $isSubheading && {
    padding: theme.space[200]
  }, $isActive && {
    backgroundColor: theme.color['fill-secondary-subtle']
  }, $disabled && {
    cursor: 'not-allowed'
  }, !$disabled && {
    '&:active': {
      backgroundColor: theme.color['fill-tertiary-hover']
    }
  }, $divider && {
    borderBlockEndStyle: 'solid',
    borderBlockEndWidth: theme.space[25],
    borderBlockEndColor: theme.color['border-primary-subtle']
  });
});
export const StyledMenuOptionContentWrapper = styled.button.withConfig({
  displayName: "ActionsMenustyles__StyledMenuOptionContentWrapper",
  componentId: "sc-13iv0w0-8"
})(({
  theme: _theme,
  $optionTextColor,
  $hasIndicator
}) => {
  const theme = _theme;
  return {
    background: 'none',
    font: 'inherit',
    cursor: 'inherit',
    outline: 'inherit',
    position: 'relative',
    border: 'none',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'flex',
    gap: $hasIndicator ? theme.space[200] : theme.space[0],
    color: theme.color[$optionTextColor]
  };
});
export const StyledMenuOptionIndicator = styled.span.withConfig({
  displayName: "ActionsMenustyles__StyledMenuOptionIndicator",
  componentId: "sc-13iv0w0-9"
})(({
  theme: _theme
}) => {
  const theme = _theme;
  return {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    height: theme.space[600],
    borderRadius: theme.borderRadius[300]
  };
});