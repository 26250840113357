const cssKeywords = ['0', 'auto', 'inherit', 'initial', 'none', 'revert', 'revert-layer', 'unset'];
const isCSSKeyword = value => {
  return cssKeywords.includes(value);
};
export const makeCustomProperty = value => {
  if (!value) {
    return;
  }
  if (value === '0') {
    return '0rem';
  }
  if (isCSSKeyword(value)) {
    return value;
  }
  return `var(--layout-lib-${value})`;
};