import { Loadable } from 'foundations-components/transitional/utils/Loadable';
import Raven from 'raven-js';
export default Loadable({
  loader: () => import( /* webpackChunkName: "ConnectEmailTourWrapper" */'./ConnectEmailTourWrapper'),
  LoadingComponent: () => null,
  ErrorComponent: ({
    error
  }) => {
    Raven.captureException(error);
    console.error(error);
    return null;
  }
});