import { useCallback } from 'react';
import memoizeOne from 'react-utils/memoizeOne';
import { useHttpClient } from '../../client/HttpClientContext';
import { getPropertyDefinitions } from '../../propertyDefinitions/getPropertyDefinitions';
import { useAsyncFunction } from '../../utils/hooks/useAsyncFunction';
import { useProperty } from './useProperty';
import { useObjectTypeId } from './useObjectTypeId';
import { usePropertyDefinitionOverride } from '../context/PropertyDefinitionOverrideContext';
import { keyBy } from '../../utils/keyBy';
const keyByName = memoizeOne(properties => keyBy(properties, ({
  property: {
    name
  }
}) => name));
const usePropertyDefinitionByName = (objectTypeId, options = {
  skip: false
}) => {
  const propertyDefinitionOverride = usePropertyDefinitionOverride();
  const httpClient = useHttpClient();
  const getDefinitions = useCallback(() => {
    return getPropertyDefinitions({
      objectTypeId,
      httpClient
    }).then(keyByName);
  }, [httpClient, objectTypeId]);
  const {
    data: propertyDefinitionsByName = {},
    error,
    loading
  } = useAsyncFunction(getDefinitions, options);
  if (propertyDefinitionOverride) {
    propertyDefinitionsByName[propertyDefinitionOverride.property.name] = propertyDefinitionOverride;
  }
  return {
    propertyDefinitionsByName,
    error,
    loading
  };
};
export const usePropertyDefinition = ({
  skip
} = {}) => {
  const property = useProperty();
  const objectTypeId = useObjectTypeId();
  const {
    propertyDefinitionsByName,
    error,
    loading
  } = usePropertyDefinitionByName(objectTypeId, {
    skip
  });
  const propertyDefinition = propertyDefinitionsByName === null || propertyDefinitionsByName === void 0 ? void 0 : propertyDefinitionsByName[property.name];
  return {
    propertyDefinition,
    error,
    loading
  };
};