// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'gene... Remove this comment to see the full error message
import { useStoreDependency } from 'general-store';
import { useMemo, useState, useEffect, useCallback } from 'react';
import TimelineEventsVirtualizedDependency from '../../data/dependencies/TimelineEventsVirtualizedDependency';
import { List, Map as ImmutableMap } from 'immutable';
import { PINNED_ENGAGEMENT, SOURCE_EVENT, EMPTY_STATE, ERROR_MESSAGE, JITA_MESSAGE } from './TimelineItemTypes';
import userInfo from 'hub-http/userInfo';
import { useIsMounted } from 'crm-events-components';
import { getSelectedFilters, getSelectedFiltersJS, TimelineOverrideFavoriteEventDependency, TimelineOwnersFilterDependency, TimelineTeamsFilterDependency, TimelineTextFilterDependency, TIMELINE_STATUSES, TRIGGER_RHUMB_FAILURE, TRIGGER_RHUMB_PARTIAL_SUCCESS, useFetchTimelineFilters, useSelectedTab, useTimelineActionHandlerContext, TimelineStatusStore, PinnedEngagementIdDependency, ShouldDisplayPinnedEngagementDependency, hasTimelineEvents, ETypes, getCreateDate, useIsScoped } from 'crm-events-data';
import useShouldRenderEmailJITACard from '../../hooks/useShouldRenderEmailJITACard';
import { daysAgo, toMoment
// @ts-expect-error not typed
} from 'UIComponents/core/SimpleDate';
const ENGAGEMENT_TYPES = ['ENGAGEMENTS_CALLS', 'ENGAGEMENTS_NOTES', 'ENGAGEMENTS_TASKS', 'ENGAGEMENTS_MEETINGS', 'ENGAGEMENTS_EMAILS', 'ENGAGEMENTS_EMAILS_TRIMMED', 'ENGAGEMENTS_CONVERSATION_SESSIONS'];
export const dependencies = {
  favoriteEventOverride: TimelineOverrideFavoriteEventDependency,
  timelineStatus: {
    stores: [TimelineStatusStore],
    deref: () => {
      return TimelineStatusStore.get('timelineStatus');
    }
  },
  pinnedEngagementId: PinnedEngagementIdDependency,
  searchText: TimelineTextFilterDependency,
  selectedTeams: TimelineTeamsFilterDependency,
  selectedOwners: TimelineOwnersFilterDependency,
  shouldDisplayPinnedEngagement: ShouldDisplayPinnedEngagementDependency
};
const sourceEvent = ImmutableMap({
  timelineItemType: SOURCE_EVENT,
  etype: ETypes.eventCreateSource
});
const emptyState = ImmutableMap({
  timelineItemType: EMPTY_STATE
});
export const useTimelineEventsData = ({
  objectType,
  scopes,
  subjectId,
  subject
}) => {
  const [portalDomain, setPortalDomain] = useState(undefined);
  const [currentUserEmail, setCurrentUserEmail] = useState(undefined);
  const isScoped = useIsScoped();
  const isMounted = useIsMounted();
  const {
    data
  } = useFetchTimelineFilters({
    subjectId,
    objectTypeOrId: objectType
  });
  const selectedEventFiltersJS = data && getSelectedFiltersJS(data.crmObject.timeline.filterGroups);
  const selectedEventFilters = data && getSelectedFilters(data.crmObject.timeline.filterGroups);
  const rawEvents = useStoreDependency(TimelineEventsVirtualizedDependency, {
    objectType,
    scopes,
    subjectId,
    subject,
    selectedEventFilters
  });
  const timelineStatus = useStoreDependency(dependencies.timelineStatus);
  const pinnedEngagementId = useStoreDependency(dependencies.pinnedEngagementId, {
    objectType,
    subjectId
  });
  const favoriteEventOverride = useStoreDependency(dependencies.favoriteEventOverride, {
    objectType,
    subject
  });
  const shouldDisplayPinnedEngagement = useStoreDependency(dependencies.shouldDisplayPinnedEngagement, {
    objectType,
    subjectId
  });
  const searchText = useStoreDependency(dependencies.searchText, {
    objectType
  });
  const selectedOwners = useStoreDependency(dependencies.selectedOwners, {
    objectType
  });
  const selectedTeams = useStoreDependency(dependencies.selectedTeams, {
    objectType
  });
  const handleTimelineAction = useTimelineActionHandlerContext();
  const {
    selectedTab
  } = useSelectedTab();
  const canViewFullActivityHistory = isScoped('full-activity-history-access');
  useEffect(() => {
    userInfo().then(value => {
      const {
        user,
        portal
      } = value;
      if (!isMounted.current) {
        return;
      }
      setCurrentUserEmail(user.email);
      // @ts-expect-error portal.domain is a legacy property
      // and is not typed in the TS version
      setPortalDomain(portal.domain);
    }).catch(e => {
      // done() is not supported by TS
      setTimeout(() => {
        throw e;
      });
    });
  }, [isMounted]);
  const isActivityTabSelected = !favoriteEventOverride;
  const hasEventFiltersSelected = useMemo(() => {
    return Boolean(selectedEventFiltersJS && selectedEventFiltersJS.length);
  }, [selectedEventFiltersJS]);
  const isErrorState = useMemo(() => {
    return timelineStatus && timelineStatus.get('status') === TIMELINE_STATUSES.ERROR;
  }, [timelineStatus]);
  const hasNoEventsAndNoErrors = useMemo(() => {
    return !hasTimelineEvents(rawEvents, timelineStatus, shouldDisplayPinnedEngagement) && !isErrorState;
  }, [rawEvents, timelineStatus, shouldDisplayPinnedEngagement, isErrorState]);
  const isUsingAdvancedFilters = useMemo(() => (searchText.length || selectedOwners.size || selectedTeams.size) > 0, [searchText, selectedOwners, selectedTeams]);
  const shouldRenderCreateSourceEvent = useMemo(() => {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const createDate = subject ? getCreateDate(subject, objectType) : undefined;
    const thirtyDaysAgoTimestamp = toMoment(daysAgo(30)).valueOf();
    const createdMoreThanThirtyDaysAgo = createDate ? +createDate < thirtyDaysAgoTimestamp : false;
    if (timelineStatus.get('hasHiddenEvents') && createdMoreThanThirtyDaysAgo) {
      return false;
    }
    return isActivityTabSelected && !timelineStatus.get('isFetching') && !timelineStatus.get('hasMore');
  }, [isActivityTabSelected, objectType, subject, timelineStatus]);
  const shouldRenderEmptyState = useMemo(() => {
    if (!hasEventFiltersSelected) {
      return isActivityTabSelected;
    }
    if (hasNoEventsAndNoErrors && (isUsingAdvancedFilters || !shouldRenderCreateSourceEvent || !canViewFullActivityHistory)) {
      return isActivityTabSelected;
    }
    return false;
  }, [canViewFullActivityHistory, hasEventFiltersSelected, hasNoEventsAndNoErrors, isActivityTabSelected, isUsingAdvancedFilters, shouldRenderCreateSourceEvent]);
  const failedOrTimedOutEvents = useMemo(() => {
    if (timelineStatus.get('status') !== TIMELINE_STATUSES.ERROR) {
      return List();
    }
    return timelineStatus.get('error').failed || List();
  }, [timelineStatus]);
  const getIsEngagement = useCallback(eventType => ENGAGEMENT_TYPES.includes(eventType), []);

  // we don't want to show the banner if only non-engagements have failed
  const isEngagementFailure = useMemo(() => timelineStatus && timelineStatus.has('error') && timelineStatus.get('error').isCompleteFailure || failedOrTimedOutEvents.some(getIsEngagement), [failedOrTimedOutEvents, timelineStatus, getIsEngagement]);
  const shouldDisplayErrorBanner = useMemo(() => failedOrTimedOutEvents.size && isEngagementFailure, [failedOrTimedOutEvents, isEngagementFailure]);
  const isEmailOrActivityTab = isActivityTabSelected || selectedTab === 'ENGAGEMENTS_EMAILS_TRIMMED';
  const shouldDisplayEmailJITACard = useShouldRenderEmailJITACard({
    currentUserEmail,
    isEmailOrActivityTab,
    objectType,
    portalDomain,
    subjectId
  });
  const pinnedEngagement = useMemo(() => ImmutableMap({
    timelineItemType: PINNED_ENGAGEMENT,
    pinnedEngagementId
  }), [pinnedEngagementId]);
  const errorMessage = useMemo(() => ImmutableMap({
    timelineItemType: ERROR_MESSAGE,
    failedOrTimedOutEvents
  }), [failedOrTimedOutEvents]);
  const jitaMessage = useMemo(() => ImmutableMap({
    timelineItemType: JITA_MESSAGE,
    currentUserEmail,
    portalDomain
  }), [currentUserEmail, portalDomain]);
  if (shouldDisplayErrorBanner) {
    handleTimelineAction(isEngagementFailure ? TRIGGER_RHUMB_FAILURE : TRIGGER_RHUMB_PARTIAL_SUCCESS);
  }
  if (shouldRenderEmptyState) {
    return List([emptyState]);
  }
  const transformedEvents = (shouldDisplayErrorBanner ? List([errorMessage]) : List()).concat(shouldDisplayEmailJITACard ? List([jitaMessage]) : List()).concat(shouldDisplayPinnedEngagement && pinnedEngagementId ? List([pinnedEngagement]) : List()).concat(rawEvents).concat(shouldRenderCreateSourceEvent ? List([sourceEvent]) : List());
  return transformedEvents;
};