// @ts-expect-error ts-migrate(2792) FIXME: Cannot find module 'crm_data/gates/IsUngatedStore'... Remove this comment to see the full error message
import IsUngatedStore from 'crm_data/gates/IsUngatedStore';
// @ts-expect-error ts-migrate(2792) FIXME: Cannot find module 'general-store'. Did you mean t... Remove this comment to see the full error message
import { useStoreDependency } from 'general-store';
// @ts-expect-error ts-migrate(2792) FIXME: Cannot find module 'crm_data/gates/withGateOverrid... Remove this comment to see the full error message
import withGateOverride from 'crm_data/gates/withGateOverride';
const useGateOverride = gate => {
  return useStoreDependency({
    stores: [IsUngatedStore],
    deref: () => {
      return withGateOverride(gate, IsUngatedStore.get(gate));
    }
  });
};
export default useGateOverride;