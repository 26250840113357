import { BUTTON_DISABLED_TEXT, HEFFALUMP } from 'HubStyleTokens/colors';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import UIIcon from 'UIComponents/icon/UIIcon';
const UITextToolbarIcon = styled(UIIcon).attrs({
  size: 14
}).withConfig({
  displayName: "UITextToolbarIcon",
  componentId: "h5wfgh-0"
})(["color:", ";"], ({
  disabled
}) => disabled ? BUTTON_DISABLED_TEXT : HEFFALUMP);
UITextToolbarIcon.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired
};
UITextToolbarIcon.displayName = 'UITextToolbarIcon';
export default UITextToolbarIcon;