const sortLookup = {
  dealstage: 'dealstage.displayOrder',
  hs_priority: 'hs_priority.displayOrder',
  hs_ticket_priority: 'hs_ticket_priority.displayOrder',
  hs_pipeline_stage: 'hs_pipeline_stage.displayOrder'
};

// We want to sort by display order for enumeration properties, which means
// "extending" the property to be property.displayOrder.
const extendPropertyName = propertyName => {
  return sortLookup[propertyName] || propertyName;
};
export const useFetchSortConfigurationDefinition = ({
  sortConfiguration
}) => {
  return sortConfiguration.map(sortConfig => {
    return {
      property: extendPropertyName(sortConfig.property),
      direction: sortConfig.direction
    };
  });
};